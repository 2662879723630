import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IPaidPlansProviderProps } from './interfaces';
import { WarmupData } from '../../services/WarmupData';
import { isPaidPlansInstalled } from './isPaidPlansInstalled';
import { loadUserPaidPlans } from '../../services/loadPaidPlans';

export async function paidPlansListPropsMap(
  flowAPI: ControllerFlowAPI,
): Promise<Pick<IPaidPlansProviderProps, 'userPaidPlans'>> {
  const warmupData = new WarmupData(flowAPI);
  const KEY_FOR_PAID_PLANS = 'paidPlansList';
  const ssrData = warmupData.get(KEY_FOR_PAID_PLANS);
  if (!(await isPaidPlansInstalled(flowAPI))) {
    return {
      userPaidPlans: [],
    };
  }
  const userPaidPlans =
    ssrData || (await loadUserPaidPlans(flowAPI.httpClient));
  warmupData.set(KEY_FOR_PAID_PLANS, ssrData);
  return {
    userPaidPlans,
  };
}
